<template>
  <div>
    <drawer title="Sales" :items="drawer.items" icon="sales"> </drawer>
    <orders v-if="tab == 'orders'" />
  </div>
</template>
<script>
// import orders component
import Orders from "../accounts/orders/Orders.vue";
import Drawer from "@/layouts/Drawer";
export default {
  components: {
    Orders,
    Drawer,
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: "Pharmacy Orders",
            icon: "mdi-cash",
            route: {
              name: "sales-orders",
              params: { type: "PharmacyOrders" },
            },
          },
          {
            title: "Repository Orders",
            icon: "mdi-cash",
            route: {
              name: "sales-orders",
              params: { type: "RepositoryOrders" },
            },
          },
          {
            title: "Doctor Orders",
            icon: "mdi-cash",
            route: {
              name: "sales-orders",
              params: { type: "DoctorOrders" },
            },
          },
        ],
      },
    };
  },
  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined) {
      this.$router.push({
        name:
          this.drawer.items[0].children != undefined
            ? this.drawer.items[0].children[0].route.name
            : this.drawer.items[0].route.name,
      });
    }
  },
};
</script>